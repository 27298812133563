<template>
  <ul
    :class="{ container_word_carousel: true, debug: Debug }"
    :style="{
      '--wordCarousel-timer': Time / 1000 + 's',
      '--wordCarousel-TextColor': textColor,
      '--wordCarousel-BgColor': bgColor,
      '--wordCarousel-sizeWindow': sizeWindow + 'px'
      // '--wordCarousel-sizeFont': sizeFont + 'px'
    }"
  >
    <li class="beforeMessage">{{ beforeMessage }}</li>
    <li
      v-for="(msg, i) of Messages"
      :key="i"
      :class="[currentIndex === i ? 'show' : 'hide']"
    >{{ msg }}</li>
    <li class="afterMessage">{{ afterMessage }}</li>
  </ul>
</template>
<script>
/*
  <word-carousel
    beforeMessage="beforeMessage"
    :Messages="['Message 1','Message 2','Message 3','Message 4']"
    afterMessage="afterMessage"
    :Time="3000"
    TextColor="black"
    BgColor="white"
  />
  <word-carousel
    :beforeMessage="wordCarousel.beforeMessage"
    :Messages="wordCarousel.Messages"
    :afterMessage="wordCarousel.afterMessage"
    :Time="wordCarousel.Time"
    :TextColor="wordCarousel.TextColor"
    :BgColor="wordCarousel.BgColor"
  />
*/

export default {
  props: {
    beforeMessage: {
      type: String,
      default: "",
    },
    Messages: {
      type: Array,
      default: () => {
        return [];
      },
    },
    afterMessage: {
      type: String,
      default: "",
    },
    Time: {
      type: Number,
      default: 2000,
    },
    textColor: {
      type: String,
      default: "black",
    },
    bgColor: {
      type: String,
      default: "red",
    },
    Size: {
      type: Number,
      default: 20,
    },
    Debug: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentIndex: 0,
      timer: null,
      // sizeWindow: 50,
      // sizeFont: 20
    };
  },
  computed: {
    sizeWindow() {
      return this.Size * 2.5;
    },
    sizeFont() {
      return this.Size;
    },
  },
  mounted() {
    this.launchTimer();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    launchTimer() {
      this.timer = setTimeout(() => {
        this.currentIndex =
          this.currentIndex >= this.Messages.length - 1
            ? 0
            : this.currentIndex + 1;
        this.launchTimer();
      }, this.Time);
    },
  },
};
</script>

<style scoped>
:root {
  --wordCarousel-timer: 3s;
  --wordCarousel-TextColor: #0fddff;
  --wordCarousel-BgColor: black;
  --wordCarousel-sizeWindow: 50px;
  --wordCarousel-sizeFont: 20px;
}

@-webkit-keyframes word_carousel {
  0% {
    margin-top: -480px;
  }
  1% {
    margin-top: -240px;
  }
  10%,
  90% {
    margin-top: 0px;
  }
  99% {
    margin-top: 240px;
  }
  100% {
    margin-top: 480px;
  }
}
@keyframes word_carousel {
  0% {
    margin-top: -480px;
  }
  1% {
    margin-top: -240px;
  }
  10%,
  90% {
    margin-top: 0px;
  }
  99% {
    margin-top: 240px;
  }
  100% {
    margin-top: 480px;
  }
}

.container_word_carousel {
  display: flex;
  padding: 0;
  list-style-type: none;
  height: var(--wordCarousel-sizeWindow);
  overflow: hidden;
  transition: all 1s;
  font-family: "Bungee", cursive;
}
.container_word_carousel li,
.beforeMessage,
.afterMessage {
  align-self: center;
  font-size: var(--wordCarousel-sizeFont);
  transition: all 1s;
}

.container_word_carousel .show {
  margin: 0 0.15em;
  padding: 0 0.15em;
  color: var(--wordCarousel-TextColor);
  background: var(--wordCarousel-BgColor);
  border-radius: 5px;
  animation-duration: var(--wordCarousel-timer);
  animation-name: word_carousel;
  transition: all 1s;
}
.container_word_carousel .hide {
  display: none;
}
</style>
